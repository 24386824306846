.thinking-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #1f9fbb;
    margin: 0 5px;
    animation: dot 1.25s ease-in-out infinite;
    &:nth-child(1) {
      animation-delay: 0.1s;
    }
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.3s;
    }
  }
  @keyframes dot {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-8px);
    }
    100% {
      transform: translateY(0);
    }
  }
}
