.card {
  // perspective: 40rem;
  //max-width: 500px;
  // width: 544px;
  max-width: 100%;
  // aspect-ratio: 4 / 6;
  font-family: var(--paragraph);

  cursor: pointer;
  transition: z-index;
  transition-delay: 0.707s;
  z-index: 0;

  // &.active {
  //   transform: scale(0.975);
  // }

  &.locked {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.card-outer {
  p {
    font-size: 1rem;
  }
}

.card-body {
  display: flex;
  transform-style: preserve-3d;
  transition: 0.707s transform;
  // aspect-ratio: 648 / 386;
  max-width: 100%;

  .card:focus &,
  .card.active & {
    // transform: rotateX(-180deg);
    z-index: 1;
  }
}

.card-front,
.card-back {
  backface-visibility: hidden;
  min-width: 100%;
}

.card-front {
  h2 {
    color: #000;
  }
  .color-black & {
    background-color: #000;
    color: #fff;

    h2 {
      color: #fff;
    }
  }

  .color-color & {
    background-color: #000;
    background-image: url(/wait-why-background.jpg);
    background-size: 110% 110%;
    background-position: center;
    color: #fff;

    h2 {
      color: #fff;
    }
  }
}

.card-back {
  // transform: rotateX(-180deg) translate(-100%, 0);
  overflow: hidden;
  // .color-white & {
  //   background-color: #000;
  //   color: #fff;
  // }

  // img {
  //   width: 100%;
  //   transform: rotate(90deg);
  //   object-fit: cover;
  //   max-width: none;
  // }
}

.card {
  display: flex;
  // transition:
  //   z-index,
  //   transform calc(0.707s / 4);
  // transition-delay: 0.707s, 0s;
  text-decoration: none;
  color: inherit;
  z-index: 0;

  &:hover {
    transition-delay: 0s;
    z-index: 1;
  }

  &:active {
    // transform: scale(0.975);
  }
}

.card-body {
  border-radius: 0.25rem;
  flex: 0 0 100%;
}

.card-front,
.card-back {
  @extend .shadow;
  display: flex;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  padding: 5rem 2rem 4rem;
  border-radius: 5%;
  // aspect-ratio: 648 / 386;
  width: 100%;
  justify-content: center;
  color: #fff;

  &.delete {
    padding: 5rem 2rem 2rem;
  }

  p {
    font-size: 1.25rem; //135px;
    font-weight: 500;
    letter-spacing: 0.01em;
    white-space: pre-line;
    line-height: 1.5;
    font-family: var(--paragraph);
    color: #fff;
    // text-align: center;
  }
}

.color-red {
  .card-front,
  .card-back {
    background-color: #cf4c67;
  }
}

.color-purple {
  .card-front,
  .card-back {
    background-color: #836ddb;
  }
}

.shadow {
  box-shadow: var(--shadow-elevation-medium);
}
