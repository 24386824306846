.card {
  perspective: 40rem;
  //max-width: 500px;
  width: 100%;
  aspect-ratio: 8 / 5;

  cursor: pointer;
  transition: z-index;
  transition-delay: 0.707s;
  z-index: 0;

  &.active {
    transform: scale(0.975);
  }

  &.locked {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.card-outer {
  p {
    font-size: 1rem;
  }
}

.card-body {
  display: flex;
  transform-style: preserve-3d;
  transition: 0.25s transform;
  aspect-ratio: 8 / 5;
  max-width: 100%;

  .card:focus &,
  .card.active & {
    transform: rotateX(-180deg);
    z-index: 1;
  }
}

.card-front,
.card-back {
  backface-visibility: hidden;
  min-width: 100%;
}

.card-front {
  h2 {
    color: #000;
  }

  .color-black & {
    background-color: #000;
    color: #fff;

    h2 {
      color: #fff;
    }
  }

  .color-color & {
    background-color: #000;
    background-image: url(/wait-why-background.jpg);
    background-size: 110% 110%;
    background-position: center;
    color: #fff;

    h2 {
      color: #fff;
    }
  }
}

.card-back {
  container-type: inline-size;

  transform: rotateY(-180deg) translate(100%, 0);
  overflow: hidden;

  .color-white & {
    background-color: #000;
    color: #fff;
  }

  img {
    width: 100%;
    transform: rotate(-90deg);
    object-fit: cover;
    max-width: none;
  }

  h2 {
    transform: rotate(180deg);
  }
}

.card {
  display: flex;
  transition:
    z-index,
    transform calc(0.707s / 4);
  transition-delay: 0.707s, 0s;
  text-decoration: none;
  color: inherit;
  z-index: 0;

  &:hover {
    transition-delay: 0s;
    z-index: 1;
  }

  &:active {
    transform: scale(0.975);
  }
}

.card-body {
  border-radius: 0.25rem;
  flex: 0 0 100%;
}

.card-front,
.card-back {
  @extend .shadow;
  display: flex;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  padding: 5vh 1vw;
  border-radius: 5%;
  aspect-ratio: 8 / 5;
  width: 100%;
  justify-content: center;

  h2 {
    // writing-mode: tb-rl;
    // transform: rotate(180deg);
    font-size: max(4rem, 3rem + 2cqi); //clamp(4rem, 10vw, 5rem); //135px;
    font-weight: 700;
    white-space: pre-line;
    line-height: 0.85;
    // letter-spacing: -0.1rem;

    // :global(.compress) {
    // 	letter-spacing: -0.1em;
    // }

    @media screen and (max-width: 600px) {
      font-size: clamp(2rem, 11vw, 4rem);
    }

    &.title {
      font-size: clamp(3rem, 6vw, 4rem);

      @media screen and (max-width: 600px) {
        font-size: clamp(2rem, 8vw, 3rem);
      }
    }
  }
}

.card-front {
  font-size: 1.5rem;
  justify-content: flex-start;
  align-items: flex-start;
  padding: clamp(2rem, 2vh, 4rem) clamp(2rem, 2vw, 4rem);
}

.size-small {
  .card-front,
  .card-back {
    // :global(h2) {
    //   // font-size: clamp(2rem, 4vw, 3rem);
    // }
  }
}

.size-tiny {
  .card-front,
  .card-back {
    h2 {
      font-size: clamp(2rem, 4vw, 3rem);
    }

    padding: clamp(2rem, 2vh, 3rem) clamp(2rem, 2vw, 3rem);
  }
}

.shadow {
  // box-shadow: var(--shadow-elevation-medium);
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.5);
}
